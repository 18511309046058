import moment from "moment";
import _ from "lodash";

export default function (
  state,
  date,
  commencedDate,
  expiredDate,
  subleaseExpirationDate,
  validContractDates,
) {
  const displayableExpiredDate = subleaseExpirationDate || expiredDate;

  if (state === "closed") {
    if (commencedDate && displayableExpiredDate && validContractDates) {
      const diff = moment(displayableExpiredDate)
        .add(1, "days")
        .diff(commencedDate, "months");
      const diffYears = _.floor(diff / 12);
      const diffMonths = diff % 12;
      const yearsMonthsLabel =
        diffMonths > 0
          ? `${diffYears} Yrs, ${diffMonths} Mths`
          : `${diffYears} Yrs`;

      return `${executionDateLabel(date)}; Term: ${shortDate(
        commencedDate,
      )} - ${shortDate(
        displayableExpiredDate,
      )}: ${diff} Mths (${yearsMonthsLabel})`;
    } else if (commencedDate && displayableExpiredDate) {
      return `${executionDateLabel(date)}; Term: ${shortDate(
        commencedDate,
      )} - ${shortDate(displayableExpiredDate)}`;
    } else if (commencedDate && !displayableExpiredDate) {
      return `${executionDateLabel(date)}; Term: ${shortDate(
        commencedDate,
      )} - ???`;
    } else if (!commencedDate && displayableExpiredDate) {
      return `${executionDateLabel(date)}; Term: ??? - ${shortDate(
        displayableExpiredDate,
      )}`;
    } else if (date) {
      return `${executionDateLabel(date)}`;
    } else {
      return "Closed, dates unknown";
    }
  } else {
    return null;
  }
}

function executionDateLabel(rawDate) {
  if (rawDate) {
    return `Executed: ${shortDate(rawDate)}`;
  } else {
    return "Closed ???";
  }
}

function shortDate(rawDate) {
  return moment(rawDate).format("M/D/YYYY");
}
